@import url('https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Fustat:wght@200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ramaraja&display=swap');


@media screen and (min-width: 1920px) {
  #root {
    overflow: hidden;
  }
  
  .main-page-content {
    overflow: hidden;
  }
  
  html {
    overflow: hidden;
  }
  
  .about-bio {
    margin-left: 10px;
    font-weight: 500;
  }

  .about-content > h3 {
    display: none;
  }

  .experience-content > h3 {
    display: none;
  }

  .projects-content > h3 {
    display: none;
  }

  .skills-content > h3 {
    display: none;
  }

  .edu-bubble {
    flex-wrap: wrap;

  }

  .links {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    height: 25px;
    padding: 0;
    margin: 0; 
  }

  .exp-date > img {
    border: 1px solid #c96346;
    opacity: .95;
    height: 86px;
    width: 81%;
    border-radius: 10px;
  }

  .links > a > img {
    height: 16px;
    margin-left: 2px;
    filter: invert(94%) sepia(16%) saturate(365%) hue-rotate(335deg) brightness(108%) contrast(105%);
  }

  body {
    overscroll-behavior-y: none;
    overflow: hidden;
    height: 100%;
  }
  
  .container {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    width: 100vw;
    height: 100%;
    background-image: linear-gradient(#8b9f81, #abc29f);
    overflow: hidden;
  }

  .division {
    min-height: 100vh;
    max-width: 100vw;
    width: 1156px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    overflow: hidden;
    gap: 100px;
  }

  .profile {
    height: 100vh;
    width: 528px;
    position: sticky;
    top: 0;
    margin-top: 0;
    display: flex;
    display: -webkit-flex;
    color: #F1D4AF;
    flex-direction: column;
    z-index: 5;
    overflow: hidden;
  }

  .profile-header {
    padding-top: 100px;
    grid-row: 2;
    height: 180px;
    align-content: center;
    vertical-align: center;
    align-items: center;
    display: flex;
    display: -webkit-flex;
    overflow: hidden;
  }

  .profile-header-content {
    display: flex;
    display: -webkit-flex;
  }

  .profile-header-text {
    height: 180px;
    align-items: center;
    display: block;

  }

  .Name {
    text-shadow: rgb(0, 0, 0, 0.1) 0px 0px 10px;
    text-align: left;
    margin-top: 8px;
    font-size: 33px;
    font-weight: 600;
    color: #efb48e;
    --a: -45deg; 
    --t: .23em;
  }

  .Name > span {
    --_s: calc(var(--t)*cos(var(--a)));
    background:
      linear-gradient(var(--a),#0000 var(--_s),currentColor 0 calc(100% - var(--_s)),#0000 0) 
      bottom/var(--i,90%) var(--t) no-repeat;
    background-position-x: 5px;
    padding: 0 .25em calc(var(--t) + .1em);
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
    transition: .3s;
    cursor: default;
  }

  .Name:hover > span {
    --i: 100%;
  }

  .Description {
    line-height: 33px;
    margin-top: -10px;
    font-weight: 600;
    font-size: 17px;
    color: #d3c371;
    --a: 45deg; 
    --t: .15em; 
    text-align: left;
  }

  .Description > span {
    --_s: calc(var(--t)*cos(var(--a)));
    background:
      linear-gradient(var(--a),#0000 var(--_s),currentColor 0 calc(100% - var(--_s)),#0000 0) 
      bottom/var(--i,90%) var(--t) no-repeat;
    background-position-x: 5px;
    padding: 0 .25em calc(var(--t) + .1em);
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
    transition: .3s;
    cursor: default;
  }

  .Description:hover > span {
    --i: 100%;
  }


  .nav-container {
    font-family: "Poppins", sans-serif; 
    height: 250px;
    margin-top: 50px;
    display: flex;
    display: -webkit-flex;
    align-items: center; 
    overflow: hidden;
  }

  .nav {
    font-size: 12px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-left: 5px;
  }

  .nav-item {
    background: #d3c371;
    font-weight: 600;
    letter-spacing: 2px;
    color: #c96346;
    position: relative;
    text-transform: uppercase;
    padding-left: 60px;
    transition: 1s;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    transition: 0.5s ease;
  }

  .nav-item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 2px;
    background-color: #c96346;
    filter: opacity(0.7);
    transition: 0.5s ease;
  }

  .nav-item.active {
    color: #c96346; 
    background-color: #e3d279;
    padding-left: 100px;
    transition: 0.5s ease;
  }

  .nav-item.active::before {
    background-color: #c96346;
    width: 80px;
    transition: 0.5s ease;
  }
  .nav-item:hover {
    cursor: pointer;
    color: #c96346;
    padding-left: 100px;
    transition: 0.5s ease;
  }

  .nav-item:hover::before {
    cursor: pointer;
    background-color: #c96346;
    width: 80px;
    transition: 0.5s ease;
  }

  .content {
    width: 528px;
    height: 100vh;
    overflow-y: auto;
    overscroll-behavior-y: none;
    color: #efb48e;
    text-shadow: rgb(0, 0, 0, 0.1) 0px 0px 10px;
    scrollbar-width: none;
  }

  .about-content {
    padding-top: 100px;
  }

  .edu-date {
    font-size: 12px;
    min-width: 170px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #783826;
    position: relative;
    text-transform: uppercase;
  }

  .edu-date > p{
    margin-top: 2px;
  }

  .edu-desc {
    display: block;
    margin-top: 0px;
  }

  .edu-desc > h4 {
    font-weight: 400;
    margin-top: 0;
  }

  .edu-desc > p {
    font-size: 12px;
    color: #b05238;
    text-shadow: rgb(255, 255, 255, 0.2) 0px 0px 10px;
  }
  .edu-bubble {
    display: flex;
    display: -webkit-flex;
    margin-left: -2px;
  }

  .edu-bubble > p {
    border: #c96346;
    border-style: solid;
    border-width: 1px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    border-radius: 15px;
    background:#e3d279;
    color: #c96346;
    font-size: 10px;
    margin: 2px;
    font-weight: 600;
    filter: opacity(0.8);
  }

  .experience-content {
    padding-top: 100px;
    margin-top: -60px;
  } 

  .exp-div {
    display: flex;
    display: -webkit-flex;
    padding: 10px;
    margin-left: -5px;
    color: #b05238;
    font-weight: 500;
  }

  .exp-date {
    font-size: 12px;
    min-width: 170px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #b05238;
    text-shadow: rgb(255, 255, 255, 0.2) 0px 0px 10px;
    position: relative;
    text-transform: uppercase;
    margin-left: 5px;
    margin-top: -9px;
  }

  .header-link {
    display: block;
    width: 100%;
    text-shadow: rgb(255, 255, 255, 0.2) 0px 0px 10px;
  }

  .header-img {
    display: flex;
    display: -webkit-flex;
    align-content: space-between;
    justify-content: space-between;
    width: 100%;
  }

  .header-img > h4 {
    font-weight: 600;
    margin-top: 0;
  }

  .header-img > img {
    margin-top: 2px;
    height: 16px;
    filter: brightness(0) saturate(100%) invert(34%) sepia(60%) saturate(879%) hue-rotate(331deg) brightness(93%) contrast(81%);
  }

  .header-link > h5 {
    margin-top: -20px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .experience-block {
    height: 100%;
    z-index: 2;
    border-radius: 10px;
  }


  .experience-block-stuffies {
    height: 100%;
    z-index: 2;
    border-radius: 10px;
  }

  .experience-block:hover {
    cursor: pointer;
    background-color: #abc29f;
    transition: 0.5s;
  }

  .experience-block-stuffies:hover {
    background-color: #abc29f;
    transition: 0.5s;
    cursor: default;
  }

  .experience-block > a {
    text-decoration: none;
  }

  .experience-block-stuffies > a {
    text-decoration: none;
  }
  .projects-content {
    padding-top: 100px;
  } 

  .skills-content {
    padding-top: 100px;
    margin-bottom: 200px;
  }

  .social-media-icons {
    display: flex;
    display: -webkit-flex;
    margin-top: 50px;
    background-color: #e3d279;
    width: 160px;
    height: 40px;
    margin-left: 5px;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    overflow: hidden;
  }
  
  .social-media-icons > a > img{
    padding: 6px;
    height: 24px;
    margin-top: 4px;
    width: 25px;
    filter: opacity(0.8);
  }

  .social-media-icons > a > img:hover{
    padding: 6px;
    height: 25px;
    width: 26px;
    filter: brightness(1.2);
  }

  .pictures {
    display: flex;
    display: -webkit-flex;
    width: 500px;
    margin-top: 40px;
    margin-left: 10px;
    justify-content: center;
  }

  .pictures > img {
    height: 220px;
    opacity: 0.95;
  }

  .guitar-img {
    margin-top: 30px;
    margin-left: -40px;
  }

}

@media screen and (max-width: 1200px) {
  #root {
    overflow: hidden;
  }
  
  .main-page-content {
    overflow: hidden;
  }
  
  html {
    overflow: hidden;
  }
  

  .division {
    gap: 50px;
  }

  .profile-header-text {
    padding-left: 20px;
    display: block;
  }

  .Name {
    font-size: 30px;
  }

  .Description {
    font-size: 15px;
  }

  .nav-container {
    margin-left: 15px;
  }

  .edu-bubble {
    flex-wrap: wrap;
  }
  
  .social-media-icons {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1920px) {
  #root {
    overflow: hidden;
  }
  
  .main-page-content {
    overflow: hidden;
  }
  
  html {
    overflow: hidden;
  }
  

  .about-bio {
    margin-left: 10px;
    font-weight: 500;
  }

  .about-content > h3 {
    display: none;
  }

  .experience-content > h3 {
    display: none;
  }

  .projects-content > h3 {
    display: none;
  }

  .skills-content > h3 {
    display: none;
  }

  .edu-bubble {
    flex-wrap: wrap;

  }

  .links {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    height: 25px;
    padding: 0;
    margin: 0; 
  }

  .exp-date > img {
    border: 1px solid #c96346;
    opacity: .95;
    height: 86px;
    width: 140px;
    border-radius: 10px;
  }

  .links > a > img {
    height: 16px;
    margin-left: 2px;
    filter: invert(94%) sepia(16%) saturate(365%) hue-rotate(335deg) brightness(108%) contrast(105%);
  }

  body {
    overscroll-behavior-y: none;
    overscroll-behavior-y: none;
    overflow-x:hidden;
    height: 100%;
  }
  
  .container {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    width: 100vw;
    height: 100%;
    background-image: linear-gradient(#8b9f81, #abc29f);
  }

  .division {
    min-height: 100vh;
    max-width: 100vw;
    width: 1156px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    gap: 100px;
  }

  .profile {
    height: 100vh;
    width: 528px;
    position: sticky;
    top: 0;
    margin-top: 0;
    display: flex;
    display: -webkit-flex;
    color: #F1D4AF;
    flex-direction: column;
    z-index: 5;
    overscroll-behavior-y: none;
  }

  .profile-header {
    padding-top: 100px;
    grid-row: 2;
    height: 180px;
    align-content: center;
    vertical-align: center;
    align-items: center;
    display: flex;
    display: -webkit-flex;
  }

  .profile-header-content {
    display: flex;
    display: -webkit-flex;
  }

  .profile-header-text {
    height: 180px;
    align-items: center;
    display: block;

  }

  .Name {
    text-shadow: rgb(0, 0, 0, 0.1) 0px 0px 10px;
    text-align: left;
    margin-top: 8px;
    font-size: 33px;
    font-weight: 600;
    color: #efb48e;
    --a: -45deg; 
    --t: .23em;
  }

  .Name > span {
    --_s: calc(var(--t)*cos(var(--a)));
    background:
      linear-gradient(var(--a),#0000 var(--_s),currentColor 0 calc(100% - var(--_s)),#0000 0) 
      bottom/var(--i,90%) var(--t) no-repeat;
    background-position-x: 5px;
    padding: 0 .25em calc(var(--t) + .1em);
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
    transition: .3s;
    cursor: default;
  }

  .Name:hover > span {
    --i: 100%;
  }

  .Description {
    line-height: 33px;
    margin-top: -10px;
    font-weight: 600;
    font-size: 17px;
    color: #d3c371;
    --a: 45deg; 
    --t: .15em; 
    text-align: left;
  }

  .Description > span {
    --_s: calc(var(--t)*cos(var(--a)));
    background:
      linear-gradient(var(--a),#0000 var(--_s),currentColor 0 calc(100% - var(--_s)),#0000 0) 
      bottom/var(--i,90%) var(--t) no-repeat;
    background-position-x: 5px;
    padding: 0 .25em calc(var(--t) + .1em);
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
    transition: .3s;
    cursor: default;
  }

  .Description:hover > span {
    --i: 100%;
  }


  .nav-container {
    font-family: "Poppins", sans-serif; 
    height: 250px;
    margin-top: 50px;
    display: flex;
    display: -webkit-flex;
    align-items: center; 
  }

  .nav {
    font-size: 12px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-left: 5px;
  }

  .nav-item {
    background: #d3c371;
    font-weight: 600;
    letter-spacing: 2px;
    color: #c96346;
    position: relative;
    text-transform: uppercase;
    padding-left: 60px;
    transition: 1s;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    transition: 0.5s ease;
  }

  .nav-item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 2px;
    background-color: #c96346;
    filter: opacity(0.7);
    transition: 0.5s ease;
  }

  .nav-item.active {
    color: #c96346; 
    background-color: #e3d279;
    padding-left: 100px;
    transition: 0.5s ease;
  }

  .nav-item.active::before {
    background-color: #c96346;
    width: 80px;
    transition: 0.5s ease;
  }
  .nav-item:hover {
    cursor: pointer;
    color: #c96346;
    padding-left: 100px;
    transition: 0.5s ease;
  }

  .nav-item:hover::before {
    cursor: pointer;
    background-color: #c96346;
    width: 80px;
    transition: 0.5s ease;
  }

  .content {
    width: 528px;
    height: 100vh;
    overflow-y: auto;
    overscroll-behavior-y: none;
    color: #efb48e;
    text-shadow: rgb(0, 0, 0, 0.1) 0px 0px 10px;
    scrollbar-width: none;
  }

  .about-content {
    padding-top: 100px;
  }

  .edu-date {
    font-size: 12px;
    min-width: 170px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #783826;
    position: relative;
    text-transform: uppercase;
  }

  .edu-date > p{
    margin-top: 2px;
  }

  .edu-desc {
    display: block;
    margin-top: 0px;
  }

  .edu-desc > h4 {
    font-weight: 400;
    margin-top: 0;
  }

  .edu-desc > p {
    font-size: 12px;
    color: #b05238;
    text-shadow: rgb(255, 255, 255, 0.2) 0px 0px 10px;
  }
  .edu-bubble {
    display: flex;
    display: -webkit-flex;
    margin-left: -2px;
  }

  .edu-bubble > p {
    border: #c96346;
    border-style: solid;
    border-width: 1px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    border-radius: 15px;
    background:#e3d279;
    color: #c96346;
    font-size: 10px;
    margin: 2px;
    font-weight: 600;
    filter: opacity(0.8);
  }

  .experience-content {
    padding-top: 100px;
    margin-top: -60px;
  } 

  .exp-div {
    display: flex;
    display: -webkit-flex;
    padding: 10px;
    margin-left: -5px;
    color: #b05238;
    font-weight: 500;
  }

  .exp-date {
    font-size: 12px;
    min-width: 170px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #b05238;
    text-shadow: rgb(255, 255, 255, 0.2) 0px 0px 10px;
    position: relative;
    text-transform: uppercase;
    margin-left: 5px;
    margin-top: -9px;
  }

  .header-link {
    display: block;
    width: 100%;
    text-shadow: rgb(255, 255, 255, 0.2) 0px 0px 10px;
  }

  .header-img {
    display: flex;
    display: -webkit-flex;
    align-content: space-between;
    justify-content: space-between;
    width: 100%;
  }

  .header-img > h4 {
    font-weight: 600;
    margin-top: 0;
  }

  .header-img > img {
    margin-top: 2px;
    height: 16px;
    filter: brightness(0) saturate(100%) invert(34%) sepia(60%) saturate(879%) hue-rotate(331deg) brightness(93%) contrast(81%);
  }

  .header-link > h5 {
    margin-top: -20px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .experience-block {
    height: 100%;
    z-index: 2;
    border-radius: 10px;
  }


  .experience-block-stuffies {
    height: 100%;
    z-index: 2;
    border-radius: 10px;
  }

  .experience-block:hover {
    cursor: pointer;
    background-color: #abc29f;
    transition: 0.5s;
  }

  .experience-block-stuffies:hover {
    background-color: #abc29f;
    transition: 0.5s;
    cursor: default;
  }

  .experience-block > a {
    text-decoration: none;
  }

  .experience-block-stuffies > a {
    text-decoration: none;
  }
  .projects-content {
    padding-top: 100px;
  } 

  .skills-content {
    padding-top: 100px;
    margin-bottom: 200px;
  }

  .social-media-icons {
    display: flex;
    display: -webkit-flex;
    margin-top: 50px;
    background-color: #e3d279;
    width: 160px;
    height: 40px;
    margin-left: 5px;
    justify-content: center;
    align-items: center;
    vertical-align: center;
  }
  
  .social-media-icons > a > img{
    padding: 6px;
    height: 24px;
    margin-top: 4px;
    width: 25px;
    filter: opacity(0.8);
  }

  .social-media-icons > a > img:hover{
    padding: 6px;
    height: 25px;
    width: 26px;
    filter: brightness(1.2);
  }

  .pictures {
    display: flex;
    display: -webkit-flex;
    width: 500px;
    margin-top: 40px;
    margin-left: 10px;
    justify-content: center;
  }

  .pictures > img {
    height: 220px;
    opacity: 0.95;
  }

  .guitar-img {
    margin-top: 30px;
    margin-left: -40px;
  }

}

@media screen and (max-width: 1200px) {
  #root {
    overflow: hidden;
  }
  
  .main-page-content {
    overflow: hidden;
  }
  
  html {
    overflow: hidden;
  }
  

  .division {
    gap: 50px;
  }

  .profile-header-text {
    padding-left: 20px;
    display: block;
  }

  .Name {
    font-size: 30px;
  }

  .Description {
    font-size: 15px;
  }

  .nav-container {
    margin-left: 15px;
  }

  .edu-bubble {
    flex-wrap: wrap;
  }
  
  .social-media-icons {
    margin-left: 20px;
  }
}

@media screen and (max-width: 950px) {
  #root {
    overflow: auto;
  }
  
  .main-page-content {
    overflow: auto;
  }
  
  html {
    overflow: auto;
  }
  

  .projects-content {
    padding-top: 40px;
  }

  .skills-content {
    padding-top: 40px;
  }

  .experience-block:hover {
    cursor: pointer;
    background-color: unset !important;
  }

  .experience-block-stuffies:hover {
    cursor: pointer;
    background-color: unset !important;
  }


  .container {
    display: block;
    width: 100vw;
  }
  .division {
    display: block;
    width: 100%;
  }
  .nav-container {
    display: none;
  }

  .headshot {
    max-height: 150px;
    min-width: 150px;
    border-radius: 75px;
    box-shadow:2px 2px rgb(38, 66, 42);
  }

  .Name {
    margin-top: 20px;
  }

  .profile {
    position: static;
    height: 180px;
    margin-left: 5vw;
  }

  .social-media-icons {
    margin-top: 10px;
    margin-left: 25px;
  }

  .profile-header {
    margin-top: 0px;
    padding-top: 20px;
  }

  .content {
    margin-top: 0px;
    padding-top: 0px;
    width: 85vw;
    height: 100%;
    margin-left: 5vw;
  }

  .about-bio {
    margin-left: 25px;
  }

  .pictures {
    width: 85vw;
    margin-top: 100px;
    margin-left: 0vw;
  }

  .phone {
    margin-left: 0px;
  }

  .about-content > h3 {
    display: flex;
    margin-left: 10px;
  }

  .experience-content > h3 {
    background-color: #e3d279;
    width: 119px;
    color: #b05238;
    display: flex;
    margin-left: 10px;
    padding-left: 7px;
  }

  .projects-content > h3 {
    background-color: #e3d279;
    width: 103px;
    color: #b05238;
    display: flex;
    margin-left: 10px;
    padding-left: 7px;
  }

  .skills-content > h3 {
    background-color: #e3d279;
    width: 105px;
    color: #b05238;
    display: flex;
    margin-left: 10px;
    padding-left: 7px;
  }

}

@media screen and (max-width: 600px) {
  #root {
    overflow: auto;
  }
  
  .main-page-content {
    overflow: auto;
  }
  
  html {
    overflow: auto;
  }
  

  .exp-date > img {
    width: 200px;
    height: 120px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .Description > a > img {
    margin-top: 2px;
  }

  .experience-block:hover {
    cursor: pointer;
    background-color: unset !important;
  }

  .experience-block-stuffies:hover {
    cursor: pointer;
    background-color: unset !important;
  }


  * {
    overscroll-behavior-y: none;
  }


  .education-block {
    padding: 0px;
    margin-bottom: 30px;
  }

  body {
    overscroll-behavior-y: none;
    background-color: rgb(53, 94, 59);
    height: 100%;
  }

  .edu-desc {
    margin-left: 0px;
  }

  .edu-desc > p {
    margin-top: 0px;
  }

  .experience-block {
    padding: 0px;
    margin-bottom: 30px;
  }

  .experience-block-stuffies {
    padding: 0px;
    margin-bottom: 30px;
  }
  
  .exp-div {
    padding: 0px;
    margin-left: 0px;
  }
  .profile {
    padding: 0px;
    height: 200px;
    width: 97vw;
    margin-left: 3vw;
  }

  .profile-header {
    height: 150px;
    margin-top: 0px;
    padding-top: 20px;
    display: block;
  }

  .headshot {
    margin-top: 0px;
    max-height: 120px;
    min-width: 120px;
    border-radius: 56px;
    box-shadow:2px 2px rgb(38, 66, 42);
  }

  .Name {
    font-size: 25px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .Description {
    margin-top: -10px;
    font-size: 15px;
    width: 250px;
  }

  .profile-header-text {
    padding-left: 0px;
  }

  .edu-div {
    display: block;
  }

  .exp-div {
    display:block;
  }
  .container {
    display: block;
    width: 100vw;
  }
  .division {
    display: block;
    width: 100%;
  }
  .nav-container {
    display: none;
  }

  .content {
    margin-top: 30px;
    width: 94vw;
    height: 100%;
    margin-left: 3vw;
  }

  .about-content {
    padding-top: 0px;
  }

  .about-content > h3 {
    font-size: 15px;
    margin-left: 0px;
  }

  .projects-content > h3 {
    font-size: 15px;
    margin-left: 0px;
    width: 85px;
  }

  .experience-content > h3 {
    width: 97px;
    font-size: 15px;
    margin-left: 0px;
  }

  .skills-content > h3 {
    width: 86px;
    font-size: 15px;
    margin-left: 0px;
  }

  .experience-content {
    margin-top: -50px;
  }

  .projects-content {
    margin-top: -50px;
  }

  .skills-content {
    margin-top: -50px;
  }

  .edu-date {
    font-size: 10px;
    min-width: 130px;
  }

  .exp-date {
    margin-left: 0px;
    margin-top: -6px;
    font-size: 10px;
    min-width: 130px;
  }

  .exp-date > p {
    margin-left: 2px;
  }

  .about-bio {
    margin-left: 5px;
  }

  .social-media-icons {
    margin-top: 10px;
    margin-left: 5px;
  }

  .pictures {
    margin-top: 60px;
    margin-left: 5px;
    width: 90vw;
  }

  .pictures > img {
    height: 180px;
    opacity: 0.95;
  }


}